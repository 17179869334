const regentradaAction = {
	id: 'idRegEntradaAction',
	selectionType: 'general', // [single|multiple|general]
	label: 'action.lspregcamion.regentradaAction',
	functionality: 'WRITE_LSPREGCAMION', // set the functionality if needed
	showInForm: true,
	checkAvailability: function(/*vue, registries*/) {
		// Validation to execute action
		return true;
	},
	runAction: function(action, model) {
		// Sample code to open a model dialog
		const row = { matricula: null };
		// Get PK for the header
		const objectPk = {};
		row.headerPk = objectPk;
		row.isAction = true;
		if (this.$puiEvents) this.$puiEvents.$emit('pui-modalDialogForm-regEntradaAction-' + model.name + '-show', row);
		else action.$puiEvents.$emit('pui-modalDialogForm-regEntradaAction-' + model.name + '-show', row);
	}
};

const regsalidaAction = {
	id: 'idRegSalidaAction',
	selectionType: 'general', // [single|multiple|general]
	label: 'action.lspregcamion.regsalidaAction',
	functionality: 'WRITE_LSPREGCAMION', // set the functionality if needed
	showInForm: true,
	checkAvailability: function(/*vue, registries*/) {
		// Validation to execute action
		return true;
	},
	runAction: function(action, model) {
		// Sample code to open a model dialog
		const row = { matricula: null };
		// Get PK for the header
		const objectPk = {};
		row.headerPk = objectPk;
		row.isAction = true;
		if (this.$puiEvents) this.$puiEvents.$emit('pui-modalDialogForm-regSalidaAction-' + model.name + '-show', row);
		else action.$puiEvents.$emit('pui-modalDialogForm-regSalidaAction-' + model.name + '-show', row);
	}
};
export default {
	gridactions: [regentradaAction, regsalidaAction],
	formactions: []
};

<template>
	<div>
		<pui-modal-dialog-form
			:titleText="$t('modal.lspregcamion.regentrada.title')"
			:modelName="modelName"
			:dialogName="lspRegEntradaModal"
			:widthDialog="850"
			disableCancel
			:okText="$t('modal.lspregcamion.regentrada.oktext')"
			:onShow="onShowRegEntradaModal"
		>
			<template slot="message" slot-scope="data">
				<v-form action ref="regEntradaForm" @submit.prevent>
					<v-layout row wrap>
						<v-flex xs12 md5>
							<pui-text-field
								:id="`contenedormatricula-lspregcamion`"
								v-model="data.modalData.contenedormatricula"
								:label="$t('lspregcamion.modal.contenedormatricula')"
								required
								toplabel
								maxlength="30"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12 md5>
							<pui-text-field
								:id="`matricula-lspregcamion`"
								v-model="data.modalData.matricula"
								:label="$t('lspregcamion.modal.matricula')"
								required
								toplabel
								maxlength="30"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12 md5>
							<pui-date-field
								:id="`fecha-lspregcamion`"
								:label="$t('lspregcamion.modal.fecha')"
								v-model="data.modalData.fecha"
								toplabel
								required
								time
							></pui-date-field>
						</v-flex>
						<v-flex xs12 md4>
							<v-btn color="primary" @click="registrarEntrada(data.modalData)" depressed class="elevation-0" style="margin-top:23px">
								{{ $t('lspregcamion.modal.registrarEntrada') }}
							</v-btn>
						</v-flex>
						<v-flex xs10 md2 v-if="showRespuesta">
							<pui-text-field
								:id="`respuesta-lspregcamion`"
								v-model="data.modalData.respuesta"
								:label="$t('lspregcamion.modal.respuesta')"
								disabled
								toplabel
								maxlength="10"
								:key="refreshSelect"
							></pui-text-field>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.lspregcamion.regsalida.title')"
			:modelName="modelName"
			:dialogName="lspRegSalidaModal"
			:widthDialog="850"
			disableCancel
			:okText="$t('modal.lspregcamion.regsalida.oktext')"
			:onShow="onShowRegSalidaModal"
		>
			<template slot="message" slot-scope="data">
				<v-form action ref="regSalidaForm" @submit.prevent>
					<v-layout row wrap>
						<v-flex xs12 md5>
							<pui-text-field
								:id="`contenedormatricula-lspregcamion`"
								v-model="data.modalData.contenedormatricula"
								:label="$t('lspregcamion.modal.contenedormatricula')"
								required
								toplabel
								maxlength="30"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12 md5>
							<pui-text-field
								:id="`matricula-lspregcamion`"
								v-model="data.modalData.matricula"
								:label="$t('lspregcamion.modal.matricula')"
								toplabel
								required
								maxlength="30"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12 md5>
							<pui-date-field
								:id="`fecha-lspregcamion`"
								:label="$t('lspregcamion.modal.fecha')"
								v-model="data.modalData.fecha"
								toplabel
								required
								time
							></pui-date-field>
						</v-flex>
						<v-flex xs12 md4>
							<v-btn color="primary" @click="registrarSalida(data.modalData)" depressed class="elevation-0" style="margin-top:23px">
								{{ $t('lspregcamion.modal.registrarSalida') }}
							</v-btn>
						</v-flex>
						<v-flex xs10 md2 v-if="showRespuesta">
							<pui-text-field
								:id="`respuesta-lspregcamion`"
								v-model="data.modalData.respuesta"
								:label="$t('lspregcamion.modal.respuesta')"
								disabled
								toplabel
								maxlength="10"
								:key="refreshSelect"
							></pui-text-field>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'lspregcamion-modals',
	data() {
		return {
			refreshSelect: 0,
			lspRegEntradaModal: 'regEntradaAction',
			lspRegSalidaModal: 'regSalidaAction',
			showRespuesta: false
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onShowRegEntradaModal(modalData) {
			this.showRespuesta = false;
			modalData.fecha = this.$moment()
				.utc()
				.format();
		},
		registrarEntrada(modalData) {
			if (modalData.contenedormatricula == null || modalData.contenedormatricula == '' || modalData.fecha == null || modalData.fecha == '') {
				this.$refs.regEntradaForm.validate();
				return;
			}
			modalData.respuesta = '';
			this.refreshSelect++;
			const params = {
				contenedorMatricula: modalData.contenedormatricula,
				matricula: modalData.matricula,
				fecha: modalData.fecha
			};

			const url = this.$store.getters.getModelByName(this.modelName).url.regEntrada;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.lspregcamion.regentrada.title');
			this.$puiRequests.getRequest(
				url,
				params,
				response => {
					modalData.respuesta = response.data;
					this.showRespuesta = modalData.respuesta != null && modalData.respuesta != '';
					this.refreshSelect++;
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		},
		onShowRegSalidaModal(modalData) {
			this.showRespuesta = false;
			modalData.fecha = this.$moment()
				.utc()
				.format();
		},
		registrarSalida(modalData) {
			if (modalData.contenedormatricula == null || modalData.contenedormatricula == '' || modalData.fecha == null || modalData.fecha == '') {
				this.$refs.regSalidaForm.validate();
				return;
			}
			modalData.respuesta = '';
			this.refreshSelect++;
			const params = {
				contenedorMatricula: modalData.contenedormatricula,
				matricula: modalData.matricula,
				fecha: modalData.fecha
			};

			const url = this.$store.getters.getModelByName(this.modelName).url.regSalida;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.lspregcamion.regsalida.title');
			this.$puiRequests.getRequest(
				url,
				params,
				response => {
					modalData.respuesta = response.data;
					this.showRespuesta = modalData.respuesta != null && modalData.respuesta != '';
					this.refreshSelect++;
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		}
	}
};
</script>

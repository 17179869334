<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs" :navigableDetail="false" readOnly></pui-datatable>
		<lspregcamion-modals :modelName="modelName"></lspregcamion-modals>
	</div>
</template>

<script>
import lspregcamionActions from './LspregcamionActions';
import lspregcamionModals from './LspregcamionModals.vue';

export default {
	name: 'lspregcamion-grid',
	components: {
		'lspregcamion-modals': lspregcamionModals
	},
	data() {
		return {
			modelName: 'lspregcamion',
			actions: lspregcamionActions.gridactions,
			modelColumnDefs: {}
		};
	},
	mounted() {
		this.loadAction(this.$router.currentRoute);
	},
	beforeRouteUpdate(to, from, next) {
		next();
		this.loadAction(to);
	},
	beforeRouteEnter(to, from, next) {
		next();
		//this.loadAction(to);
	},
	beforeRouteLeave(to, from, next) {
		next();
		this.loadAction(to);
	},
	methods: {
		loadAction(to) {
			const currentRouter = to.path;
			if (currentRouter === '/lspregcamion_in') {
				//ejecuto la accion de consulta para abrir tcon y modal a la vez
				const model = { name: this.modelName };
				this.actions[0].runAction(this, model);
				this.$router.push({ path: '/'.concat('lspregcamion_in1') });
			} else if (currentRouter === '/lspregcamion_out') {
				//ejecuto la accion de consulta para abrir tcon y modal a la vez
				const model = { name: this.modelName };
				this.actions[1].runAction(this, model);
				this.$router.push({ path: '/'.concat('lspregcamion_out1') });
			}
		}
	}
};
</script>
